import React from "react";
import AppHeaderBody from "./AppHeaderBody";


const AppHeader = () => {
    return(
        <AppHeaderBody />
    )
}

export default AppHeader;
