import React from "react";
import ContactBody from "./ContactBody";

const Contact = () => {
  return (
    <ContactBody />
  )
}

export default Contact;
